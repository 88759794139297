const $ = require('jquery');
const modal = require('./modal');
const get = require('lodash/get');
const countdown = require('./countdown');

const webroot = get(globals, 'webroot', '');

module.exports = {
  // parāda paziņojumu, ka nepieciešams samaksāt, lai lietotu pilnvērtīgi OP
  subscriptionMessage() {
    const url = `${webroot}messages/subscription_message`;
    modal.ajaxModal(url);
  },
  pollMessage() {
    const url = `${webroot}messages/poll_popup`;
    modal.ajaxModal(url, false, null, null, 800, 'vex-theme-default vex-theme-nopadding vex-lg-padding vex-max-height vex-overflow-auto', null, null, 'only_close');
  },
  // parāda admin ziņas
  adminMessages() {
    const url = `${webroot}messages/admin_messages`;
    const closeCallback = () => {
      module.exports.subscriptionMessage();
    };
    // modal.ajaxModal(url, false, null, null, null, 'vex-theme-default vex-max-height vex-theme-external-images', null, closeCallback, 'only_close');
    modal.ajaxModal(url, false, null, null, 800, 'vex-theme-default vex-lg-padding vex-max-height vex-overflow-auto', null, closeCallback, 'only_close');
  },
  // parāda paziņojumu, ka nepieciešams samaksāt, lai nebūtu limits sarakstēm
  overConversationLimitMessage() {
    const url = `${webroot}messages/over_conversation_limit_message`;
    modal.ajaxModal(url, false, null, null, 500, 'vex-theme-default vex-lg-padding vex-max-height vex-overflow-auto');
  },
  // parāda paziņojumu, ka nepieciešams admina apstirpinājums, lai lietotu pilnvērtīgi OP
  pendingMessage() {
    const url = `${webroot}messages/pending_message`;
    modal.ajaxModal(url);
  },
  // ziņot par lietotāju
  reportMember(memberId) {
    if (!memberId) {
      return;
    }
    const url = `${webroot}member/${memberId}/report`;

    // norāda target priekš formas
    const ajaxData = {
      form_target: '#vex-content',
    };

    // izmanto pjax
    modal.ajaxModal(url, true, '#vex-content', ajaxData, 720);
  },
  editcardMessage() {
    const url = `${webroot}messages/edit_card_recurring`;
    modal.ajaxModal(url);
  },
  stoprecurringMessage() {
    const url = `${webroot}messages/stop_recurring`;
    modal.ajaxModal(url);
  },
  itunesDeleteMessage() {
    const url = `${webroot}messages/itunes_warning`;
    modal.ajaxModal(url);
  },
  invalidCardMessage(callback) {
    const url = `${webroot}messages/invalid_card`;
    modal.ajaxModal(url);
  },
  unverifiedMessage(callback) {
    const url = `${webroot}messages/unverified`;
    modal.ajaxModal(url, null, null, null, null, null, null, callback);
  },
  newTerms() {
    const url = `${webroot}members/new_terms_popup`;
    modal.ajaxModal(url, false, null, null, 800, 'vex-theme-default vex-lg-padding vex-max-height vex-overflow-auto', null, null, 'strict');
  },
  newPrivacyPolicy() {
    const url = `${webroot}member/new_privacy_policy_popup/`;
    modal.ajaxModal(url, false, null, null, 800, 'vex-theme-default vex-lg-padding vex-max-height vex-overflow-auto', null, null, 'strict');
  },
  thumbCenterPopup(href, callback) {
    modal.ajaxModal(href, null, null, null, null, null, callback);
  },
  adminConfirmMessage(msg, url) {
    const callback = function (result) {
      if (result) {
        if(url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
          window.location = url;
        } else {
          window.location = webroot + url;
        }
      }
    };
    modal.confirm(msg, callback);
  },
  // parāda paziņojumu, ka nepieciešams noņemt masku, lai veiktu kādu rīcību
  maskInfoMessage() {
    const url = `${webroot}members/mask_info`;
    modal.ajaxModal(url);
  },
  // parāda paziņojumu, ka nepieciešams verificēt epastu, lai varētu pilnīgi izmantot portālu
  verificationMessage() {
    const url = `${webroot}auth/resend_code?popup=true`;
    // modal.ajaxModal(url, false, null, null, 700, 'vex-theme-default vex-small-margin');
    modal.ajaxModal(url, false, null, null, 700, 'vex-theme-default');
  },
  externalImageFacebook() {
    const url = `${webroot}images/external_images/facebook`;
    modal.ajaxModal(url, false, null, null, 862, 'vex-theme-default vex-max-height vex-theme-external-images');
  },
  externalImageInstagram() {
    const url = `${webroot}images/external_images/instagram`;
    modal.ajaxModal(url, false, null, null, 862, 'vex-theme-default vex-max-height vex-theme-external-images');
  },
  externalImageAlbum(href) {
    const url = webroot + href;
    modal.ajaxModal(url, false, null, null, 862, 'vex-theme-default vex-max-height vex-theme-external-images');
  },
  imageUpload(query) {
    let url = `${webroot}images/upload_popup`;
    if (query) {
      url += query;
    }
    modal.ajaxModal(url, false, null, null, null, 'vex-theme-default vex-lg-padding vex-upload-popup');
  },
  shareStory() {
    const url = `${webroot}members/share_story_form`;
    modal.ajaxModal(url, false, null, null, 550, 'vex-story-form vex-theme-default vex-lg-padding');
  },
  featureMessage(feature) {
    const url = `${webroot}messages/feature_message/${feature}`;
    modal.ajaxModal(url);
  },
  profileVerificationMessage() {
    const url = `${webroot}messages/profile_verification_message`;

    const callback = () => {
      const container = '.verification-countdown-inner';
      const $container = $(container);
      if (!$container.length) {
        return;
      }
      const endtime = $container.data('countdownEndtime');
      const format = $container.data('countdownFormat');
      countdown.init(container, endtime, get(globals, 'locale', 'lv'), format);
    };

    modal.ajaxModal(url, null, null, null, null, null, callback);
  },
  // parāda paziņojumu, ka PDF būs par garu
  /* pdfLimitMessage(member_id) {
    const url = `${webroot}messages/pdf_limit_message/${member_id}`;
    modal.ajaxModal(url);
  }, */
  pdfSender(status) {
    const url = `${webroot}messages/pdf_sender_popup/${status}`;
    modal.ajaxModal(url);
  },
  windowPopup(url, width, height) {
    let leftPosition,
      topPosition;
    leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    window.open(url, 'Window2', `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=yes,location=no,directories=no`);
  },
  popupOnLoad() {
    const $popupMessage = $('[data-popup-message]');
    if ($popupMessage.length) {
      modal.alert($popupMessage.data('popupMessage'), get(translations, 'txt: Sapratu', 'txt: Sapratu'));
    }
    const url = window.location.href;
    if (url.indexOf('/home') === -1 && get(globals, 'popups.showPollPopup', false)) {
      module.exports.pollMessage();
    }
  },
  facebookDisabled() {
    const infoText = 'txt: Autorizēšanās ar Facebook īslaicīgi nav pieejama. Lai autorizētos, spied uz "Aizmirsu paroli".';
    modal.alert(get(translations, infoText, infoText), get(translations, 'txt: Sapratu', 'txt: Sapratu'));
  },
  vkDisabled() {
    const infoText = 'txt: Autorizēšanās ar VK īslaicīgi nav pieejama. Lai autorizētos, spied uz "Aizmirsu paroli".';
    modal.alert(get(translations, infoText, infoText), get(translations, 'txt: Sapratu', 'txt: Sapratu'));
  },
  vipOnMessage() {
    const infoText = 'txt: Darbību nevar veikt, atrodoties VIP režīmā.';
    modal.alert(get(translations, infoText, infoText), get(translations, 'txt: Sapratu', 'txt: Sapratu'));
  },
  matchesVerificationMessage() {
    const url = `${webroot}messages/matches_verification_message`;
    modal.ajaxModal(url);
  },
  init() {
    $(document).on('click', '#editCardInfo', (e) => {
      e.preventDefault();
      module.exports.editcardMessage();
    });
    $(document).on('click', '#stopRecurringInfo', (e) => {
      e.preventDefault();
      module.exports.stoprecurringMessage();
    });
    $(document).on('click', '[data-subscription-message]', (e) => {
      e.preventDefault();
      module.exports.subscriptionMessage();
    });
    $(document).on('click', '[data-noads-message]', (e) => {
      e.preventDefault();
      module.exports.featureMessage('noads');
    });
    // data-searchname-message
    // firefox nevar klikšķināt uz disablota lauka, tāpēc dummy
    $(document).on('click', '[data-searchname-message] > .dummy', (e) => {
      e.preventDefault();
      module.exports.featureMessage('search_name');
    });
    $(document).on('click', '[data-maskinfo-message]', (e) => {
      e.preventDefault();
      module.exports.maskInfoMessage();
    });
    $(document).on('click', '[data-verification-message]', function (e) {
      if ($(this).data('verificationMessage')) {
        e.preventDefault();
        module.exports.verificationMessage();
      }
    });
    $(document).on('click', '#externalImagesFacebook', (e) => {
      e.preventDefault();
      module.exports.externalImageFacebook();
    });
    $(document).on('click', '#externalImagesInstagram', (e) => {
      e.preventDefault();
      module.exports.externalImageInstagram();
    });
    $(document).on('click', '.imagesThumb-image--album', function (e) {
      e.preventDefault();
      module.exports.externalImageAlbum($(this).attr('href'));
    });
    $(document).on('click', '#upload_popup', (e) => {
      e.preventDefault();
      module.exports.imageUpload();
    });
    $(document).on('click', '#share-story', (e) => {
      e.preventDefault();
      module.exports.shareStory();
    });
    const _this = this;
    $(document).on('click', '[data-popup]', function (e) {
      e.preventDefault();
      let width = 600;
      if (window.innerWidth < width) {
        width = window.innerWidth;
      }
      _this.windowPopup(this.href, width, 500);
    });
    $(document).on('click', '[data-show-popup]', function (e) {
      e.preventDefault();
      modal.ajaxModal(webroot + $(this).attr('data-show-popup'), null, null, null, null, null, null);
    });
    $(document).on('click', '[data-alert]', function (e) {
      e.preventDefault();
      const infoText = $(this).attr('data-alert');
      modal.alert(get(translations, infoText, infoText), get(translations, 'txt: Sapratu', 'txt: Sapratu'));
    });
    $(document).on('click', '#delete-me-button[data-itunes-warning=1]', (e) => {
      e.preventDefault();
      _this.itunesDeleteMessage();
    });
    $(document).on('click', '.facebook-disabled', (e) => {
      e.preventDefault();
      _this.facebookDisabled();
    });
    $(document).on('click', '.vk-disabled', (e) => {
      e.preventDefault();
      _this.vkDisabled();
    });

    $(document).ready(() => {
      const url = window.location.href;

      if (url.indexOf('?show_instagram_popup=1') != -1) {
			   module.exports.externalImageInstagram();
      }

      if (url.indexOf('?show_facebook_popup=1') != -1) {
			   module.exports.externalImageFacebook();
      }

      if (url.indexOf('?show_no_image_popup=1') != -1) {
			   module.exports.imageUpload('?show_no_image_popups=1');
      }
    });

    // ja servera pusē flash message tipu uzliek 'popup'
    _this.popupOnLoad();
    $(document).on('pjax:end', _this.popupOnLoad);
  },
};
